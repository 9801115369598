import { useCallback, useRef } from 'react';

import { AuthKind, LoginComponentSourceName } from '../types';
import env from '../../../../config/environment';
import {
  getClientOperationMetadataInBase64,
  getDeviceFromUserAgent,
  getOrCreateUnloggedId,
} from '../../../../infra/api/core/tracker';

export enum SocialPopupUrls {
  GOOGLE = '/auth/google',
  FACEBOOK = '/auth/facebook',
  APPLE = '/auth/apple',
}

export type PopupCenterProps = {
  width: number;
  height: number;
};

const getFinalUrl = (
  currentUrl: string,
  authKind: AuthKind,
  componentSourceName?: LoginComponentSourceName,
  path?: string
) => {
  // TODO: [sc-18707] Deveria buscar do modal de termos, essa é uma solução temporariaBus
  const termsParam = authKind === AuthKind.LOGIN ? 'false' : 'true';

  /* eslint-disable @typescript-eslint/restrict-template-expressions */
  const url = new URL(`${env.PUBLIC_REST_API_URL}${currentUrl}`);

  const { userAgent } = navigator;

  url.searchParams.set('strategySource', authKind);
  url.searchParams.set('agreedWithTerms', termsParam);
  url.searchParams.set(
    'clientInfo',
    getClientOperationMetadataInBase64(
      {
        componentSourceName,
        path,
        clientDevice: getDeviceFromUserAgent(userAgent),
        clientUserAgent: userAgent,
      },
      true
    )
  );
  url.searchParams.set('unloggedId', getOrCreateUnloggedId() || '');

  return url;
};

const useSocialPopup = ({ width, height }: PopupCenterProps) => {
  const externalWindow = useRef<Window | null>(null);

  const openSocialPopup = useCallback(
    (
      url: SocialPopupUrls,
      authKind: AuthKind,
      componentSourceName?: LoginComponentSourceName,
      path?: string
    ) => {
      const dualScreenLeft = window.screenLeft || window.screenX;
      const dualScreenTop = window.screenTop || window.screenY;

      const windowWidth =
        window.innerWidth || document.documentElement.clientWidth;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

      const systemZoom = windowWidth / window.screen.availWidth;

      const left = (windowWidth - width) / 2 / systemZoom + dualScreenLeft;
      const top = (windowHeight - height) / 2 / systemZoom + dualScreenTop;

      const features = `width=${width}, height=${height}, left=${left}, top=${top}`;

      const finalUrl = getFinalUrl(url, authKind, componentSourceName, path);

      externalWindow.current = window.open(finalUrl.href, '', features);

      if (externalWindow.current) {
        externalWindow.current.focus();
      }
    },
    [height, width]
  );

  const closeSocialPopup = useCallback(() => {
    if (externalWindow.current) {
      externalWindow.current.close();
    }
  }, [externalWindow]);

  return {
    openSocialPopup,
    closeSocialPopup,
  };
};

export default useSocialPopup;
