import { Button } from '@pelando/components';
import styled, { css } from 'styled-components';

import { AuthProvider, SocialProvidersType } from '../../types';

export type SocialButtonContainerProps = {
  provider: SocialProvidersType;
  hasAvatar: boolean;
  responsive: boolean;
};

const appleStyles = css`
  &,
  &:hover {
    background: rgb(0, 0, 0);
    border: 1px solid rgb(0, 0, 0);
    color: rgb(255, 255, 255);
  }

  &:hover {
    opacity: 0.85;
  }
`;

const googleStyles = css`
  background: rgb(255, 255, 255);
  border: 1px solid rgb(233, 233, 233);
  color: rgb(77, 77, 77);

  &:hover {
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
  }

  &::before {
    content: '';
    height: 24px;
    width: 24px;
    display: block;
    background-image: url('/assets/google.svg');
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 8px;
  }
`;

const facebookStyles = ({
  responsive,
  hasAvatar,
}: SocialButtonContainerProps) => css`
  background: #4267b2;
  border: 1px solid #4267b2;

  &:hover {
    background: #34528e;
    border: 1px solid #34528e;
  }

  ${!responsive &&
  hasAvatar &&
  css`
    padding-right: 48px;
  `}
`;

const providerStyles = {
  [AuthProvider.APPLE]: appleStyles,
  [AuthProvider.FACEBOOK]: facebookStyles,
  [AuthProvider.GOOGLE]: googleStyles,
};

export const SocialButtonContainer = styled(Button)<SocialButtonContainerProps>`
  height: 48px;
  ${({ provider }) => providerStyles[provider]}
`;
