import { Checkbox, Icon } from '@pelando/components';
import React, { ReactNode, useState } from 'react';
import { Apple, Facebook } from '@pelando/fontawesome/icons';
import { CheckBoxSizeType } from '@pelando/components/components/Checkbox/types';
import { useTranslation } from '../../../../hooks/useTranslation';
import Translation from '../../../Translation';
import { AuthProvider } from '../../types';
import ExternalAgreementLink from '../ExternalAgreementLink';
import { ResetPasswordButton } from '../ResetPassword/style';
import StepHeader, { StepHeaderType } from '../StepHeader';
import {
  AcceptTermsHeader,
  AcceptTermsLabel,
  AcceptTermsSpan,
  AcceptTermsSubtitle,
  AcceptTermsTitle,
  Container,
  FacebookIcon,
  GoogleIcon,
} from './style';
import { AgreementType } from '../../../../../domain/entities/Agreement';

type TermsCheckboxLabelProps = {
  children: ReactNode;
  translation: string;
};

const TermsCheckboxLabel = ({
  children,
  translation,
}: TermsCheckboxLabelProps) => (
  <AcceptTermsLabel>
    {children}
    <AcceptTermsSpan>
      <Translation translation={translation}>
        Confirmo que li, aceito e concordo com os
        <ExternalAgreementLink agreementType={AgreementType.TERMS_OF_SERVICE} />
        ,
        <ExternalAgreementLink agreementType={AgreementType.CODE_OF_CONDUCT} />
        e
        <ExternalAgreementLink agreementType={AgreementType.PRIVACY_POLICY} />
        do Pelando.
      </Translation>
    </AcceptTermsSpan>
  </AcceptTermsLabel>
);

type AcceptTermsProps = {
  onSubmit: () => void;
  socialLogin: AuthProvider;
  onGoBack: () => void;
};

function AcceptTerms({ onSubmit, socialLogin, onGoBack }: AcceptTermsProps) {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation('login');

  const mapAuthProviderToText = (authProvider: AuthProvider) =>
    ((
      {
        [AuthProvider.APPLE]: t('authenticated-apple'),
        [AuthProvider.FACEBOOK]: t('authenticated-facebook'),
        [AuthProvider.GOOGLE]: t('authenticated-google'),
      } as Record<AuthProvider, string>
    )[authProvider]);

  return (
    <Container>
      <StepHeader onButtonClick={onGoBack} type={StepHeaderType.GO_BACK} />
      <AcceptTermsHeader>
        {socialLogin === AuthProvider.APPLE && <Icon icon={Apple} />}
        {socialLogin === AuthProvider.FACEBOOK && (
          <FacebookIcon icon={Facebook} />
        )}
        {socialLogin === AuthProvider.GOOGLE && <GoogleIcon />}
        <AcceptTermsSubtitle>
          {mapAuthProviderToText(socialLogin)}
        </AcceptTermsSubtitle>
        <AcceptTermsTitle>
          <Translation translation={t('login-modal-accept-terms-title')}>
            Você concorda com nossas <br /> políticas e termos?
          </Translation>
        </AcceptTermsTitle>
      </AcceptTermsHeader>
      <TermsCheckboxLabel translation={t('accept-terms')}>
        <Checkbox
          checkboxSize={CheckBoxSizeType.MEDIUM}
          checked={checked}
          onChange={() => setChecked((previousChecked) => !previousChecked)}
        />
      </TermsCheckboxLabel>
      <ResetPasswordButton disabled={!checked} onClick={onSubmit}>
        {t('login-step-continue-button')}
      </ResetPasswordButton>
    </Container>
  );
}

export default AcceptTerms;
