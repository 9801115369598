import {
  BodyLight,
  Button,
  CaptionHeavy,
  CaptionLight,
  Colors,
  H2Heavy,
  H3Heavy,
  Icon,
  MediaQuery,
  Theme,
} from '@pelando/components';
import styled, { css } from 'styled-components';

const googleStyles = css`
  &::after {
    content: '';
    height: 24px;
    width: 24px;
    display: flex;
    background-image: url('/assets/google.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const AcceptTermsHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  justify-content: center;
  margin-bottom: 24px;

  i {
    align-self: center;
    font-size: 24px;
  }
`;

export const AcceptTermsTitle = styled.div`
  ${H3Heavy}
  text-align: center;
  margin-top: 16px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H2Heavy}
  }
`;

export const AcceptTermsButton = styled(Button)`
  height: 48px;
`;

export const AcceptTermsLabel = styled.label`
  display: flex;
`;

export const AcceptTermsSpan = styled.span`
  margin: 0 0 8px 8px;
  ${CaptionLight}

  a {
    color: rgb(${Theme.colors.Alpha});
    ${CaptionHeavy}
  }
`;
export const AcceptTermsSubtitle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  ${BodyLight}
  color: rgb(${Colors.Gray})
`;

export const FacebookIcon = styled(Icon)`
  color: rgb(${Colors.Blue});
`;

export const GoogleIcon = styled.div`
  display: flex;
  justify-content: center;
  ${googleStyles}
`;
