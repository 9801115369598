import React from 'react';
import { CheckBoxSizeType } from '@pelando/components/components/Checkbox/types';
import { useTranslation } from '../../../../hooks/useTranslation';
import Translation from '../../../Translation';
import ExternalAgreementLink from '../ExternalAgreementLink';
import { AcceptTermsLabel, CustomCheckBox } from './style';
import { AgreementType } from '../../../../../domain/entities/Agreement';

export const FORM_TITLE = 'Você concorda com nossas políticas e termos?';
export const SUBMIT_LABEL = 'Continuar';

type AcceptTermsProps = {
  onChecked: () => void;
  checked: boolean;
};

function AcceptTermsCheckbox({ onChecked, checked }: AcceptTermsProps) {
  const { t } = useTranslation('login');
  return (
    <AcceptTermsLabel>
      <CustomCheckBox
        checked={checked}
        onChange={onChecked}
        checkboxSize={CheckBoxSizeType.MEDIUM}
      />

      <span>
        <Translation translation={t('accept-terms')}>
          Confirmo que li, aceito e concordo com os
          <ExternalAgreementLink
            agreementType={AgreementType.TERMS_OF_SERVICE}
          />
          ,
          <ExternalAgreementLink
            agreementType={AgreementType.CODE_OF_CONDUCT}
          />
          e
          <ExternalAgreementLink agreementType={AgreementType.PRIVACY_POLICY} />
          do Pelando.
        </Translation>
      </span>
    </AcceptTermsLabel>
  );
}

export default AcceptTermsCheckbox;
