import React from 'react';
import { IconDefinition } from '@pelando/fontawesome';
import { Apple, Facebook } from '@pelando/fontawesome/icons';
import { useTranslation } from '../../../../hooks/useTranslation';
import { capitalizeWord } from '../../../../services/strings';
import {
  AuthProvider,
  FacebookLoginData,
  SocialProvidersType,
} from '../../types';
import { SocialButtonContainer } from './style';

export type SocialButtonProps = {
  className?: string;
  provider: SocialProvidersType;
  userProviderData?: Pick<FacebookLoginData['user'], 'name' | 'image'>;
  responsive?: boolean;
  onClick?: () => void;
};

const socialIcons: Record<SocialProvidersType, IconDefinition | undefined> = {
  [AuthProvider.APPLE]: Apple,
  [AuthProvider.FACEBOOK]: Facebook,
  [AuthProvider.GOOGLE]: undefined,
};

function SocialButton({
  className,
  provider,
  userProviderData,
  onClick,
  responsive,
}: SocialButtonProps) {
  const { t } = useTranslation('login');

  const getSocialButtonText = ({
    provider,
  }: Pick<SocialButtonProps, 'provider'>) => {
    const labelByProvider: Record<SocialProvidersType, string> = {
      [AuthProvider.APPLE]: t('social-button-text-apple'),
      [AuthProvider.FACEBOOK]: `${t('social-button-text')} ${capitalizeWord(
        provider
      )}`,
      [AuthProvider.GOOGLE]: `${t('social-button-text')} ${capitalizeWord(
        provider
      )}`,
    };

    return labelByProvider[provider];
  };

  const socialText = getSocialButtonText({ provider });
  const label = getSocialButtonText({ provider });

  return (
    <SocialButtonContainer
      hasAvatar={!!userProviderData}
      provider={provider}
      icon={socialIcons[provider]}
      iconPosition="left"
      responsive={responsive || false}
      onClick={onClick}
      className={className}
      aria-label={label}
    >
      {socialText}
    </SocialButtonContainer>
  );
}

export default SocialButton;
