import React from 'react';
import { IconDefinition } from '@pelando/fontawesome';
import { Apple, Facebook } from '@pelando/fontawesome/icons';
import { AuthProvider, SocialProvidersType } from '../../types';
import { SocialButtonContainer } from './style';

export type SocialButtonProps = {
  className?: string;
  provider: SocialProvidersType;
  responsive?: boolean;
  onClick?: () => void;
};

const socialIcons: Record<SocialProvidersType, IconDefinition | undefined> = {
  [AuthProvider.APPLE]: Apple,
  [AuthProvider.FACEBOOK]: Facebook,
  [AuthProvider.GOOGLE]: undefined,
};

export const getSocialButtonLabel = ({
  provider,
}: Pick<SocialButtonProps, 'provider'>) => provider;

function SocialMediaButton({
  className,
  provider,
  onClick,
  responsive,
}: SocialButtonProps) {
  return (
    <SocialButtonContainer
      icon={socialIcons[provider]}
      iconPosition="left"
      responsive={responsive || false}
      onClick={onClick}
      className={className}
      aria-label={provider}
      provider={provider}
      light
    />
  );
}

export default SocialMediaButton;
