import {
  Icon,
  TextInput,
  TextInputMessage,
  TextInputMessageStatus,
  TextInputProps,
} from '@pelando/components';
import { useEffect, useRef, useState } from 'react';
import { TriangleExclamation } from '@pelando/fontawesome/icons';
import validate from '../../../../services/validator';
import AcceptTermsCheckbox from '../AcceptTermsCheckbox';
import AddPasswordInput from '../AddPasswordInput';

import {
  FormSignupButton,
  FormSignupContainer,
  FormSignupErrorMessageContainer,
  FormSignupLabel,
  FormSignupPasswordContainer,
  TextInputContainer,
} from './style';
import { useTranslation } from '../../../../hooks/useTranslation';

export type FormSignupProps = TextInputProps & {
  email?: string;
  customError?: string;
  onClickToSubmit: (email: string, password: string) => void;
};

function FormSignup({ email, customError, onClickToSubmit }: FormSignupProps) {
  const [emailInput, setEmailInput] = useState(email || '');
  const [password, setPassword] = useState('');
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
  const [checked, setChecked] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [emailMessage, setEmailMessage] = useState<TextInputMessage>();
  const [passwordMessage, setPasswordMessage] = useState<TextInputMessage>();
  const [confirmPasswordMessage, setConfirmPasswordMessage] =
    useState<TextInputMessage>();
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isConfirmPasswordInvalid, setIsConfirmPasswordInvalid] =
    useState(false);
  const [hasInteracted, setHasInteracted] = useState({
    email: false,
    password: false,
    confirmPassword: false,
  });

  const { t } = useTranslation('login', 'common');

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    const isEmailInvalid = !validate(emailInput, { email: true });
    setIsEmailInvalid(isEmailInvalid);

    if (isEmailInvalid && hasInteracted.email) {
      setEmailMessage({
        type: TextInputMessageStatus.ERROR,
        text: t('login-error-message-invalid-email'),
      });
    } else {
      setEmailMessage(undefined);
    }
  }, [emailInput, hasInteracted.email, t]);

  useEffect(() => {
    const isPasswordInvalid = !validate(password, { minLength: 8 });
    setIsPasswordInvalid(isPasswordInvalid);

    if (isPasswordInvalid && hasInteracted.password) {
      setPasswordMessage({
        type: TextInputMessageStatus.ERROR,
        text: t('login-error-password-minimum-limit'),
      });
    } else {
      setPasswordMessage(undefined);
    }

    const isConfirmPasswordInvalid = password !== confirmPasswordValue;
    setIsConfirmPasswordInvalid(isConfirmPasswordInvalid);

    if (isConfirmPasswordInvalid && hasInteracted.confirmPassword) {
      setConfirmPasswordMessage({
        type: TextInputMessageStatus.ERROR,
        text: t('login-error-password-must-be-the-same'),
      });
    } else {
      setConfirmPasswordMessage(undefined);
    }
  }, [
    password,
    confirmPasswordValue,
    hasInteracted.password,
    hasInteracted.confirmPassword,
    t,
  ]);

  const handleEmailChange = (inputValue: string) => {
    setEmailInput(inputValue);
    setHasInteracted((prev) => ({ ...prev, email: true }));
  };

  const handlePasswordChange = (password: string) => {
    setPassword(password);
    setHasInteracted((prev) => ({ ...prev, password: true }));
  };

  const handleConfirmPasswordChange = (confirmPassword: string) => {
    setConfirmPasswordValue(confirmPassword);
    setHasInteracted((prev) => ({ ...prev, confirmPassword: true }));
  };

  const handlePasswordInputFocus = () => {
    setPasswordMessage({
      type: TextInputMessageStatus.TIP,
      text: t('login-tip-password-minimum-limit'),
    });
  };

  const handleSubmit = () => {
    onClickToSubmit(emailInput, password);
  };

  const hasError = customError;

  const isPasswordOrConfirmPasswordInvalid =
    isPasswordInvalid || isConfirmPasswordInvalid;

  return (
    <FormSignupContainer onSubmit={handleSubmit}>
      <TextInputContainer>
        <FormSignupLabel>{t('login-step-label')}</FormSignupLabel>
        <TextInput
          innerRef={inputRef}
          placeholder={t('placeholder-email')}
          defaultValue={email}
          value={emailInput}
          onChange={({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => handleEmailChange(value)}
          type="email"
          invalid={isEmailInvalid && hasInteracted.email}
          message={emailMessage}
        />
        <FormSignupPasswordContainer>
          <AddPasswordInput
            onPasswordChange={handlePasswordChange}
            isConfirmPasswordInvalid={
              isConfirmPasswordInvalid && hasInteracted.confirmPassword
            }
            isPasswordInvalid={isPasswordInvalid && hasInteracted.password}
            onConfirmPasswordChange={handleConfirmPasswordChange}
            passwordMessage={passwordMessage}
            confirmPasswordMessage={confirmPasswordMessage}
            onPasswordInputFocus={handlePasswordInputFocus}
          />
        </FormSignupPasswordContainer>
      </TextInputContainer>
      <AcceptTermsCheckbox
        onChecked={() => setChecked(!checked)}
        checked={checked}
      />
      <FormSignupButton
        type="submit"
        disabled={
          isEmailInvalid ||
          isPasswordOrConfirmPasswordInvalid ||
          !checked ||
          confirmPasswordValue === ''
        }
      >
        {t('login-step-continue-button')}
      </FormSignupButton>
      {hasError && (
        <FormSignupErrorMessageContainer>
          <Icon
            icon={TriangleExclamation}
            aria-label={t('error-icon-aria-label')}
          />
          {customError}
        </FormSignupErrorMessageContainer>
      )}
    </FormSignupContainer>
  );
}

export default FormSignup;
