import { ChevronLeft } from '@pelando/fontawesome/icons';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { Container, GoBackButtonIcon } from './styles';

export enum StepHeaderType {
  CLOSE = 'CLOSE',
  GO_BACK = 'GO_BACK',
}

type StepHeaderProps = {
  onButtonClick?: () => void;
  type?: StepHeaderType;
};

const StepHeader = ({
  onButtonClick,
  type = StepHeaderType.CLOSE,
}: StepHeaderProps) => {
  const { t } = useTranslation('login');
  const isBackButton = type === StepHeaderType.GO_BACK;

  return (
    <Container>
      {isBackButton && (
        <GoBackButtonIcon
          icon={ChevronLeft}
          aria-label={t('step-header-go-back-button')}
          role="button"
          onClick={onButtonClick}
        />
      )}
    </Container>
  );
};

export default StepHeader;
