import {
  BodyLight,
  Button,
  Colors,
  H2Heavy,
  H3Heavy,
  Icon,
  MediaQuery,
  SmallLight,
  Theme,
} from '@pelando/components';
import styled from 'styled-components';
import BaseSocialButton from '../SocialButton';

export const LoginEmailSocialStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 100%;
  align-items: center;
`;

export const LoginEmailSocialStepTitle = styled.div`
  ${H3Heavy}

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H2Heavy}
  }
`;

export const LoginEmailSocialStepEmail = styled.div`
  ${BodyLight}
  text-align: center;
  margin-top: 8px;
  margin-bottom: 32px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-bottom: 16px;
  }

  &[data-finish='true'] {
    margin-bottom: 24px;
  }
`;

export const LoginEmailSocialStepSubtitle = styled.div`
  ${BodyLight}
  text-align: center;
  margin: 8px 0 16px;
  color: rgb(${Colors.Gray});

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin: 8px 0 24px;
  }
`;

export const SocialButton = styled(BaseSocialButton)`
  padding: 0;
  i {
    margin-right: 8px;
    margin-bottom: 0;
  }
`;

export const CreatePasswordButton = styled(Button)`
  margin-top: 8px;
  height: 48px;
  width: 100%;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-top: 16px;
  }

  &[data-finish='true'] {
    margin-top: 0;
  }
`;

export const EnvelopeIcon = styled(Icon)`
  color: rgb(${Theme.colors.Brand});
  margin-bottom: 0;
`;

export const TermsOfUse = styled.div`
  ${SmallLight}
  color: rgb(${Colors.Gray});
  margin-top: 16px;
  text-align: center;
  a {
    color: rgb(${Colors.Gray});
  }
`;
