import React, { ReactNode } from 'react';
import { useTranslation } from '../../../../hooks/useTranslation';
import { AuthProvider } from '../../types';
import StepHeader, { StepHeaderType } from '../StepHeader';
import {
  AddEmailForm,
  AddEmailFormInput,
  EmailContainer,
  EmailStepContainer,
  EmailStepModalSubtitle,
  EmailStepModalTitle,
} from './style';

export type EmailStepProps = {
  className?: string;
  modalTitle?: ReactNode;
  modalSubtitle?: ReactNode;
  onPick: (provider: AuthProvider) => void;
  handleSubmitEmail: (email: string) => void;
  onCloseModal: () => void;
  onGoBack: () => void;
};

function EmailStep({ className, handleSubmitEmail, onGoBack }: EmailStepProps) {
  const { t } = useTranslation('login');

  return (
    <>
      <EmailStepContainer className={className}>
        <StepHeader onButtonClick={onGoBack} type={StepHeaderType.GO_BACK} />
        <EmailStepModalTitle>
          <>{t('login-step-title')}</>
        </EmailStepModalTitle>
        <EmailContainer>
          <AddEmailForm>
            <EmailStepModalSubtitle>
              {t('login-step-label')}
            </EmailStepModalSubtitle>
            <AddEmailFormInput
              isSignUp
              placeholder={t('login-placeholder')}
              required
              type="email"
              name="email"
              onSubmit={(email) => handleSubmitEmail(email as string)}
              formatter={(value: string) => value.toLowerCase()}
              validations={{
                rules: {
                  email: true,
                },
                errorMessage: t('login-error-message-invalid-email'),
              }}
              btnText={t('login-btn-text')}
            />
          </AddEmailForm>
        </EmailContainer>
      </EmailStepContainer>
    </>
  );
}
export default EmailStep;
