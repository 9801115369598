import { PasswordInput, TextInputMessage } from '@pelando/components';

import {
  AddPasswordInputContainer,
  PasswordInputLabel,
  PasswordInputWrapper,
} from './style';
import { useTranslation } from '../../../../hooks/useTranslation';

type PasswordInputProps = {
  onPasswordChange: (password: string) => void;
  onPasswordInputFocus: () => void;
  onConfirmPasswordChange: (password: string) => void;
  isPasswordInvalid: boolean;
  isConfirmPasswordInvalid: boolean;
  confirmPasswordMessage?: TextInputMessage;
  passwordMessage?: TextInputMessage;
};

function AddPasswordInput({
  onPasswordChange,
  onConfirmPasswordChange,
  isConfirmPasswordInvalid,
  isPasswordInvalid,
  confirmPasswordMessage,
  passwordMessage,
  onPasswordInputFocus,
}: PasswordInputProps) {
  const { t } = useTranslation('login');

  return (
    <AddPasswordInputContainer>
      <PasswordInputLabel>{t('signup-step-password-label')}</PasswordInputLabel>
      <PasswordInputWrapper>
        <PasswordInput
          placeholder={t('signup-step-create-password-placeholder')}
          onChange={({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) => onPasswordChange(value)}
          message={passwordMessage}
          onFocus={onPasswordInputFocus}
          invalid={isPasswordInvalid}
        />
      </PasswordInputWrapper>
      <PasswordInputWrapper>
        <PasswordInput
          placeholder={t('signup-step-confirm-password-placeholder')}
          onChange={({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement>) =>
            onConfirmPasswordChange(value)
          }
          message={confirmPasswordMessage}
          invalid={isConfirmPasswordInvalid}
        />
      </PasswordInputWrapper>
    </AddPasswordInputContainer>
  );
}

export default AddPasswordInput;
