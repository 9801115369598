import {
  Icon,
  Colors,
  MediaQuery,
  BodyLight,
  Button,
  H2Heavy,
  H3Heavy,
  Theme,
} from '@pelando/components';
import styled from 'styled-components';

interface ResetIconProps {
  success: boolean;
}

export const ResetPasswordContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const ResetPasswordHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  justify-content: center;
  margin-bottom: 24px;
`;

export const ResetPasswordIcon = styled(Icon)<ResetIconProps>`
  color: rgb(${(props) => (props.success ? Colors.Alien : Theme.colors.Brand)});
  margin-top: ${(props) => (props.success ? '0' : '6px')};
  margin-bottom: 24px;
  align-self: center;
  font-size: 48px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-bottom: 16px;
  }
`;

export const ResetPasswordTitle = styled.div`
  ${H3Heavy}
  text-align: center;
  margin-bottom: 8px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H2Heavy}
  }
`;

export const ResetPasswordSubtitle = styled.div`
  display: flex;
  justify-content: center;
  ${BodyLight}
  color: rgb(${Colors.Gray})
`;

export const ResetPasswordButton = styled(Button)`
  margin-top: 16px;
  height: 48px;

  &[data-finish='true'] {
    margin-top: 0;
  }
`;
