import { FontFamily } from '@pelando/components';
import styled from 'styled-components';

type TitleContainerProps = {
  align?: string;
};

const TitleContainer = styled.h1<TitleContainerProps>`
  font-family: ${FontFamily.DM_SANS};
  font-weight: 700;
  line-height: 32px;
  font-size: 24px;
  margin: 0;
  text-align: ${(props) => (props.align ? props.align : 'left')};
`;

export default TitleContainer;
