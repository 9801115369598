import React, { FormEvent, useCallback } from 'react';
import { useTranslation } from '../../../../hooks/useTranslation';

import { FacebookLoginData } from '../../types';
import StepHeader, { StepHeaderType } from '../StepHeader';
import {
  SocialEmailConfirmationContainer,
  SocialEmailConfirmationFormInput,
} from './style';

export type SocialEmailConfirmationProps = {
  user: FacebookLoginData['user'];
  onSubmit: (email: string) => void;
  customError?: string;
  onGoBack: () => void;
};

function SocialEmailConfirmation({
  onSubmit,
  user,
  customError,
  onGoBack,
}: SocialEmailConfirmationProps) {
  const { t } = useTranslation('login');

  const handleOnSubmit = useCallback(
    (value: string | FormEvent<HTMLInputElement>) => {
      if (typeof value !== 'string') return;

      onSubmit(value);
    },
    [onSubmit]
  );

  return (
    <SocialEmailConfirmationContainer>
      <StepHeader onButtonClick={onGoBack} type={StepHeaderType.GO_BACK} />
      <SocialEmailConfirmationFormInput
        placeholder="ex.: rafa@gmail.com"
        title={t('social-email-confirmation-title')}
        subtitle={t('social-email-confirmation-subtitle')}
        defaultValue={user.email}
        required
        onSubmit={handleOnSubmit}
        validations={{
          rules: {
            email: true,
          },
          errorMessage: t('login-error-message-invalid-email'),
        }}
        btnText={t('login-step-continue-button')}
        customError={customError}
      />
    </SocialEmailConfirmationContainer>
  );
}

export default SocialEmailConfirmation;
