import styled from 'styled-components';
import { BodyHeavy, Button, Colors, Small } from '@pelando/components';

export const FormSignupContainer = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const FormSignupButton = styled(Button)`
  height: 48px;
`;

export const FormSignupLabel = styled.span`
  ${BodyHeavy}
  margin-bottom: 8px;
`;

export const FormSignupErrorMessageContainer = styled.span`
  ${Small}
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-top: 4px;
  color: rgb(${Colors.Red});

  i {
    margin-right: 4px;
    font-size: 24px;
  }
`;

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormSignupPasswordContainer = styled.div`
  margin: 16px 0 24px;
`;
