import { H3Heavy } from '@pelando/components';
import styled from 'styled-components';
import FormInput from '../FormInput';

export const SocialEmailConfirmationContainer = styled.div`
  width: 100%;
`;

export const SocialEmailConfirmationFormInput = styled(FormInput)`
  margin-top: 8px;

  h1 {
    ${H3Heavy}
  }
`;
