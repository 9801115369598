import {
  Caption,
  CaptionLink,
  Checkbox,
  Colors,
  Theme,
} from '@pelando/components';
import styled from 'styled-components';

export const AcceptTermsLabel = styled.label`
  ${Caption}
  margin-bottom: 24px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  color: rgb(${Colors.Gray});

  a {
    ${CaptionLink}
    font-weight: bold;
    color: rgb(${Theme.colors.Alpha});
  }
`;

export const CustomCheckBox = styled(Checkbox)`
  i {
    margin-top: 1px;
  }
`;
