import { useEffect, useRef, useState } from 'react';

export type WizardControlsProps<TStep> = {
  currentStep: TStep;
  goToStep: (step: TStep) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
};

export type WizardProps<TStep> = {
  steps: ReadonlyArray<TStep>;
  defaultStep?: Readonly<TStep>;
  onStepChange?: (step: TStep) => void;
  renderStep: (props: WizardControlsProps<TStep>) => JSX.Element | null;
};

const Wizard = <TStep,>({
  steps,
  defaultStep,
  onStepChange,
  renderStep,
}: WizardProps<TStep>) => {
  const [step, goToStep] = useState(defaultStep || steps[0]);

  useEffect(() => {
    if (onStepChange) {
      onStepChange(step);
    }
  }, [step, onStepChange]);

  useEffect(() => {
    if (defaultStep) goToStep(defaultStep);
  }, [defaultStep]);

  const { current: goToNextStep } = useRef(() =>
    goToStep((currentStep) => {
      const currentStepIndex = steps.indexOf(currentStep);

      if (currentStepIndex === steps.length - 1) {
        return currentStep;
      }

      return steps[currentStepIndex + 1];
    })
  );

  const { current: goToPreviousStep } = useRef(() =>
    goToStep((currentStep) => {
      const currentStepIndex = steps.indexOf(currentStep);

      if (currentStepIndex === 0) {
        return currentStep;
      }

      return steps[currentStepIndex - 1];
    })
  );

  return renderStep({
    currentStep: step,
    goToStep,
    goToNextStep,
    goToPreviousStep,
  });
};

export default Wizard;
