import { H2Heavy, H3Heavy, MediaQuery } from '@pelando/components';
import styled from 'styled-components';

export const SignupStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 100%;
  align-items: center;

  @media ${MediaQuery.SCREEN_MD_UP} {
    padding-top: 8px;
  }
`;

export const SignupStepHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  justify-content: center;
  margin-bottom: 24px;

  i {
    align-self: center;
    font-size: 48px;
  }
`;

export const SignupStepTitle = styled.div`
  ${H3Heavy}
  text-align: center;

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H2Heavy}
  }
`;

export const SignupStepFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
