import { MutableRefObject, ReactNode } from 'react';

import {
  AppleLoginData,
  AuthKind,
  AuthModalHandler,
  AuthProvider,
  FacebookLoginData,
  GoogleLoginData,
  LoginComponentSourceName,
} from '../../types';
import { WizardControlsProps } from '../Wizard';

export type AuthenticationData = {
  email: string;
  password: string;
};

export enum AuthenticationSteps {
  SOCIAL_LOGIN = 'SocialLogin',
  EMAIL_STEP = 'EmailStep',
  ACCEPT_TERMS = 'AcceptTerms',
  LOGIN_STEP = 'LoginStep',
  SIGNUP_STEP = 'SignupStep',
  SOCIAL_EMAIL_CONFIRMATION = 'SocialEmailConfirmation',
  LOGIN_EMAIL_SOCIAL_STEP = 'LoginEmailSocialStep',
}

export enum AuthenticationEventLabel {
  ACCEPTPOLICY = 'accept_policy',
  EMAIL = 'email',
  PASSWORD = 'password',
}

export type AuthenticationWizardControls =
  WizardControlsProps<AuthenticationSteps>;

export type AuthenticationWizardProps = {
  authKind: AuthKind.LOGIN | AuthKind.SIGNUP | AuthKind.LOGIN_BETA;
  defaultProvider?: AuthProvider;
  loading?: boolean;
  customError?: string;
  modalTitle?: ReactNode;
  modalSubtitle?: ReactNode;
  componentSourceName?: LoginComponentSourceName;
  path?: string;
  handleEmailAuthentication: ({ email, password }: AuthenticationData) => void;
  handleEmailSignup: ({ email, password }: AuthenticationData) => void;
  handleFacebookAuthentication: () => Promise<FacebookLoginData>;
  handleGoogleAuthentication: () => Promise<GoogleLoginData>;
  handleAppleAuthentication: () => Promise<AppleLoginData>;
  handleUpdateEmail: (email: string) => void;
  handleAuthModal: AuthModalHandler;
  handleStepChange?: (step: AuthenticationSteps) => void;
  wizardControls?: MutableRefObject<AuthenticationWizardControls | undefined>;
  onCloseModal: () => void;
  getAuthProviders: (email: string) => Promise<Array<string> | undefined>;
  handleRequestPasswordReset: (email: string) => Promise<boolean>;
  googleClientId?: string;
  onFinished?: () => void;
};
