import { BodyHeavy } from '@pelando/components';
import styled from 'styled-components';

export const AddPasswordInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const PasswordInputLabel = styled.span`
  ${BodyHeavy}
`;

export const PasswordInputWrapper = styled.div`
  & > div {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  span {
    margin: 0;
  }
`;
