import { BodyHeavy, H3Heavy, MediaQuery } from '@pelando/components';
import styled from 'styled-components';
import FormInput, { FormInputProps } from '../FormInput';

export const EmailStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const EmailStepModalTitle = styled.h1`
  ${H3Heavy}
  text-align: center;
`;

export const EmailStepModalSubtitle = styled.div`
  ${BodyHeavy}
  margin-bottom: 8px;
`;

export const EmailContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 20px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-top: 24px;
    margin-bottom: 28px;
  }
`;

export const AddEmailForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const AddEmailFormInput = styled(FormInput)<
  FormInputProps & { isSignUp: boolean }
>`
  justify-content: ${(props) => (props.isSignUp ? 'flex-end' : 'center')};
`;
