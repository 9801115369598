import { EMAIL_REGEX } from './regex';
import { stripTags } from './strings';

export const minLengthValidator = (value: string, minLength: number): boolean =>
  value.length >= minLength;

const emailValidator = (value: string): boolean => !!value.match(EMAIL_REGEX);

export type RulesValidate = {
  minLength?: number;
  link?: boolean;
  email?: boolean;
};

const validate = (value: string, rules: RulesValidate) => {
  let isValid = true;

  Object.entries(rules).forEach(([rule, ruleValue]) => {
    switch (rule) {
      case 'minLength':
        isValid = minLengthValidator(value, ruleValue as number);
        break;

      case 'email':
        isValid = emailValidator(value);
        break;

      default:
        break;
    }
  });

  return isValid;
};

export const hasNonWhitespaceText = (value?: string) =>
  /\S/.test(stripTags(value || '').replace(/&nbsp;/g, ''));

export default validate;
