import {
  BodyHeavy,
  Button,
  CaptionLink,
  H2Heavy,
  H3Heavy,
  MediaQuery,
} from '@pelando/components';
import styled from 'styled-components';

export const LoginStepContainer = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const LoginStepHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  justify-content: center;
  margin-bottom: 24px;

  i {
    align-self: center;
    font-size: 48px;
  }
`;

export const LoginStepTitle = styled.div`
  ${H3Heavy}
  text-align: center;

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H2Heavy}
  }
`;

export const LoginStepLabel = styled.span`
  ${BodyHeavy}
  margin-bottom: 8px;
  display: block;
`;

export const LoginStepRecoveryLink = styled(Button)`
  ${CaptionLink}
  width: max-content;
  font-weight: bold;
  margin: 0 auto;
  margin-bottom: 24px;
  background: transparent;
  color: rgb(${({ theme }) => theme.colors.Alpha});
  padding: 0px;
  min-height: auto;

  &:hover {
    background: transparent;
    color: rgb(${({ theme }) => theme.colors.Alpha});
  }
`;

export const LoginStepButton = styled(Button)`
  height: 48px;
`;

export const EmailInputContainer = styled.div`
  margin-bottom: 32px;

  & > div {
    display: flex;
    flex-direction: column;
  }

  &[data-invalid='true'] {
    margin-bottom: 0;
  }

  span {
    margin-bottom: 4px;
  }
`;

export const PasswordInputContainer = styled.div`
  margin-bottom: 32px;

  & > div {
    display: flex;
    flex-direction: column;
  }

  &[data-invalid='true'] {
    margin-bottom: 0;
  }

  span {
    margin-bottom: 4px;
  }
`;
