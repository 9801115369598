import {
  PasswordInput,
  TextInput,
  TextInputMessage,
  TextInputMessageStatus,
} from '@pelando/components';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from '../../../../hooks/useTranslation';
import validate from '../../../../services/validator';
import { AuthKind, AuthModalHandler } from '../../types';
import { AuthenticationData } from '../AuthenticationWizard/types';
import StepHeader, { StepHeaderType } from '../StepHeader';

import {
  LoginStepButton,
  LoginStepContainer,
  LoginStepHeader,
  LoginStepLabel,
  LoginStepRecoveryLink,
  LoginStepTitle,
  EmailInputContainer,
  PasswordInputContainer,
} from './style';

export type LoginStepProps = {
  email?: string;
  customError?: string;
  handleEmailAuthentication: ({ email, password }: AuthenticationData) => void;
  handleAuthModal?: AuthModalHandler;
  onGoBack: () => void;
};

function LoginStep({
  handleEmailAuthentication,
  email,
  handleAuthModal,
  onGoBack,
}: LoginStepProps) {
  const { t } = useTranslation('login');
  const [emailInput, setEmailInput] = useState(email || '');
  const [password, setPassword] = useState('');
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [emailMessage, setEmailMessage] = useState<TextInputMessage>();
  const [passwordMessage, setPasswordMessage] = useState<TextInputMessage>();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const handleChange = (inputValue: string) => {
    setEmailInput(inputValue);
  };

  const handleSubmit = () => {
    handleEmailAuthentication({ email: emailInput, password });
  };

  const handleForgotPassword = () => {
    if (handleAuthModal) {
      handleAuthModal({ authKind: AuthKind.RESET_PASS, email });
    }
  };

  const handleEmailInputBlur = () => {
    const isInvalid = !validate(emailInput, { email: true });
    setIsEmailInvalid(isInvalid);
    if (isInvalid) {
      setEmailMessage({
        type: TextInputMessageStatus.ERROR,
        text: t('login-error-message-invalid-email'),
      });
      return;
    }
    setEmailMessage(undefined);
  };

  const handlePasswordInputBlur = () => {
    const isInvalid = !validate(password, { minLength: 6 });
    setIsPasswordInvalid(isInvalid);
    if (isInvalid) {
      setPasswordMessage({
        type: TextInputMessageStatus.ERROR,
        text: t('login-error-password-minimum-limit'),
      });
      return;
    }
    setPasswordMessage(undefined);
  };

  const isSubmitDisabled =
    !validate(emailInput, { email: true }) ||
    !validate(password, { minLength: 6 });

  return (
    <LoginStepContainer onSubmit={handleSubmit}>
      <StepHeader onButtonClick={onGoBack} type={StepHeaderType.GO_BACK} />
      <LoginStepHeader>
        <LoginStepTitle>{t('login-step-title')}</LoginStepTitle>
      </LoginStepHeader>
      <LoginStepLabel>{t('login-step-label')}</LoginStepLabel>
      <EmailInputContainer data-invalid={isEmailInvalid}>
        <TextInput
          innerRef={inputRef}
          placeholder={t('placeholder-email')}
          defaultValue={emailInput}
          value={emailInput}
          onBlur={handleEmailInputBlur}
          onFocus={() => {
            setIsEmailInvalid(false);
            setEmailMessage(undefined);
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(event.target.value)
          }
          type="email"
          invalid={isEmailInvalid}
          message={emailMessage}
        />
      </EmailInputContainer>
      <div>
        <LoginStepLabel>{t('signup-step-password-label')}</LoginStepLabel>
        <PasswordInputContainer data-invalid={isPasswordInvalid}>
          <PasswordInput
            placeholder={t('placeholder-password')}
            value={password}
            onBlur={handlePasswordInputBlur}
            onFocus={() => {
              setIsPasswordInvalid(false);
              setPasswordMessage(undefined);
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value);
            }}
            invalid={isPasswordInvalid}
            message={passwordMessage}
          />
        </PasswordInputContainer>
      </div>
      <LoginStepRecoveryLink onClick={handleForgotPassword}>
        {t('login-step-recovery')}
      </LoginStepRecoveryLink>
      <LoginStepButton type="submit" disabled={isSubmitDisabled}>
        {t('login-step-continue-button')}
      </LoginStepButton>
    </LoginStepContainer>
  );
}

export default LoginStep;
