import {
  BannerType,
  HEADER_HEIGHT,
  Icon,
  useModal,
  useToast,
} from '@pelando/components';
import React, { useState } from 'react';
import { Apple, Envelope, Facebook } from '@pelando/fontawesome/icons';
import { useTranslation } from '../../../../hooks/useTranslation';
import Translation from '../../../Translation';
import { AuthProvider, SocialProvidersType } from '../../types';
import { FacebookIcon, GoogleIcon } from '../AcceptTerms/style';
import ExternalAgreementLink from '../ExternalAgreementLink';
import StepHeader, { StepHeaderType } from '../StepHeader';
import {
  CreatePasswordButton,
  EnvelopeIcon,
  LoginEmailSocialStepContainer,
  LoginEmailSocialStepEmail,
  LoginEmailSocialStepSubtitle,
  LoginEmailSocialStepTitle,
  SocialButton,
  TermsOfUse,
} from './style';
import { AgreementType } from '../../../../../domain/entities/Agreement';

export type LoginEmailSocialStepProps = {
  email?: string;
  socialLogin: AuthProvider;
  onPick: (provider: AuthProvider) => void;
  handleRequestPasswordReset: (email: string) => Promise<boolean>;
  onGoBack: () => void;
};

function LoginEmailSocialStep({
  email,
  socialLogin,
  handleRequestPasswordReset,
  onPick,
  onGoBack,
}: LoginEmailSocialStepProps) {
  const { t } = useTranslation('login');
  const { showToast } = useToast();
  const [success, setSuccess] = useState(false);
  const { closeModal } = useModal();

  const socialLoginText: { [index in string]: string } = {
    apple: t('linked-apple'),
    google: t('linked-goole'),
    facebook: t('linked-facebook'),
  };

  const titleText = success
    ? t('check-email')
    : t('social-network-modal-title');

  const subtitleText = success ? (
    <span>
      <Translation translation={t('link-create-password')}>
        Enviamos um link para que você <br /> crie a sua senha.
      </Translation>
    </span>
  ) : (
    <span>
      <Translation translation={t('email-linked-social-network')}>
        O email desta conta está <br /> vinculada
      </Translation>{' '}
      {socialLoginText[socialLogin]}.
    </span>
  );

  const buttonText = success
    ? t('login-reset-pass-button-text-sucess')
    : t('create-password');

  const handleResetPass = async () => {
    if (!email) return;

    try {
      const data = await handleRequestPasswordReset(email);
      setSuccess(data);
    } catch {
      showToast({
        bannerType: BannerType.ERROR,
        titleText: t('login-toast-banner-error-title'),
        topPosition: HEADER_HEIGHT,
        text: t('login-toast-banner-email-reset-pass'),
      });
    }
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const headerIcon: Record<AuthProvider, JSX.Element | undefined> = {
    [AuthProvider.APPLE]: <Icon style={{ fontSize: 24 }} icon={Apple} />,
    [AuthProvider.FACEBOOK]: (
      <FacebookIcon style={{ fontSize: 24 }} icon={Facebook} />
    ),
    [AuthProvider.GOOGLE]: <GoogleIcon />,
    [AuthProvider.LOCAL]: undefined,
  };

  const mapAgreementTypeToText = (agreementType: AgreementType) =>
    ((
      {
        [AgreementType.PRIVACY_POLICY]: t('login-privacy-policy'),
        [AgreementType.TERMS_OF_SERVICE]: t('login-terms-of-service'),
      } as Record<AgreementType, string>
    )[agreementType]);

  return (
    <LoginEmailSocialStepContainer>
      <StepHeader
        onButtonClick={success ? closeModal : onGoBack}
        type={success ? StepHeaderType.CLOSE : StepHeaderType.GO_BACK}
      />
      {success ? (
        <EnvelopeIcon icon={Envelope} role="img" style={{ fontSize: 48 }} />
      ) : (
        headerIcon[socialLogin]
      )}
      <LoginEmailSocialStepEmail data-finish={success}>
        {email}
      </LoginEmailSocialStepEmail>
      <LoginEmailSocialStepTitle>{titleText}</LoginEmailSocialStepTitle>
      <LoginEmailSocialStepSubtitle>
        {subtitleText}
      </LoginEmailSocialStepSubtitle>
      {!success && (
        <SocialButton
          provider={socialLogin as SocialProvidersType}
          responsive
          onClick={() => onPick(socialLogin)}
        />
      )}
      {email && (
        <CreatePasswordButton
          onClick={success ? handleCloseModal : handleResetPass}
          data-finish={success}
        >
          {buttonText}
        </CreatePasswordButton>
      )}
      {!success && (
        <TermsOfUse>
          {t('login-terms-of-use-text')} <br />
          <ExternalAgreementLink
            agreementType={AgreementType.TERMS_OF_SERVICE}
            getAgreementLinkText={mapAgreementTypeToText}
          />{' '}
          {t('login-terms-of-use-joining')}
          <ExternalAgreementLink
            agreementType={AgreementType.PRIVACY_POLICY}
            getAgreementLinkText={mapAgreementTypeToText}
          />
        </TermsOfUse>
      )}
    </LoginEmailSocialStepContainer>
  );
}

export default LoginEmailSocialStep;
