import { BannerType, TextInput, useModal, useToast } from '@pelando/components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Envelope } from '@pelando/fontawesome/icons';
import { useTranslation } from '../../../../hooks/useTranslation';
import validate from '../../../../services/validator';
import { HEADER_HEIGHT } from '../../../Header/styles';
import StepHeader, { StepHeaderType } from '../StepHeader';
import {
  ResetPasswordButton,
  ResetPasswordContainer,
  ResetPasswordHeader,
  ResetPasswordIcon,
  ResetPasswordSubtitle,
  ResetPasswordTitle,
} from './style';

export type ResetPasswordProps = {
  handleRequestPasswordReset: (email: string) => Promise<boolean>;
  defaultEmail?: string;
  onGoBack: () => void;
};

function ResetPassword({
  handleRequestPasswordReset,
  defaultEmail,
  onGoBack,
}: ResetPasswordProps) {
  const { t } = useTranslation('login');
  const { showToast } = useToast();
  const [emailInput, setEmailInput] = useState(defaultEmail || '');
  const [success, setSuccess] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const { closeModal } = useModal();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const validations = {
    rules: {
      email: true,
    },
    errorMessage: t('login-error-message-invalid-email'),
  };
  const handleValidationEmail = useCallback(() => {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    if (validate(inputRef.current!.value, validations.rules)) {
      return true;
    }

    return false;
  }, [validations.rules]);

  const handleChange = (inputValue: string) => {
    setEmailInput(inputValue);

    if (isEmailInvalid && handleValidationEmail()) {
      setIsEmailInvalid(false);
    }
  };

  const handleResetPass = async () => {
    try {
      const data = await handleRequestPasswordReset(emailInput);
      setSuccess(data);
    } catch {
      showToast({
        bannerType: BannerType.ERROR,
        titleText: t('login-toast-banner-error-title'),
        topPosition: HEADER_HEIGHT,
        text: t('login-toast-banner-email-reset-pass'),
      });
    }
  };

  const handleCloseModal = () => {
    closeModal();
    onGoBack();
  };

  const title = success
    ? t('login-reset-pass-sucess')
    : t('login-reset-pass-email');

  const buttonText = success
    ? t('login-reset-pass-button-text-sucess')
    : t('login-step-continue-button');

  return (
    <ResetPasswordContainer>
      <StepHeader
        onButtonClick={onGoBack}
        type={success ? StepHeaderType.CLOSE : StepHeaderType.GO_BACK}
      />
      <ResetPasswordHeader>
        <ResetPasswordIcon success={success} icon={Envelope} role="img" />
        <ResetPasswordTitle data-testid="reset-password-title">
          {title}
        </ResetPasswordTitle>
        {!success ? (
          <ResetPasswordSubtitle data-testid="reset-password-subtitle">
            {t('reset-password-subtitle-sucess')}
            <br /> {t('reset-password-subtitle-sucess-continued')}
          </ResetPasswordSubtitle>
        ) : (
          <ResetPasswordSubtitle data-testid="reset-password-subtitle">
            {t('reset-password-subtitle')}
            <br /> {t('reset-password-subtitle-continued')}
          </ResetPasswordSubtitle>
        )}
      </ResetPasswordHeader>
      {!success && (
        <TextInput
          innerRef={inputRef}
          placeholder={t('placeholder-email')}
          defaultValue={emailInput}
          value={emailInput}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(event.target.value)
          }
          type="email"
        />
      )}
      <ResetPasswordButton
        disabled={isEmailInvalid || !emailInput}
        onClick={success ? handleCloseModal : handleResetPass}
        data-finish={success}
      >
        {buttonText}
      </ResetPasswordButton>
    </ResetPasswordContainer>
  );
}

export default ResetPassword;
