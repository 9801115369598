import React from 'react';

import FormSignup from '../FormSignup';
import StepHeader, { StepHeaderType } from '../StepHeader';
import {
  SignupStepContainer,
  SignupStepFormContainer,
  SignupStepHeader,
  SignupStepTitle,
} from './style';
import { useTranslation } from '../../../../hooks/useTranslation';

export type SignupStepProps = {
  email?: string;
  customError?: string;
  onSubmit: (email: string, password: string) => void;
  onGoBack: () => void;
};

function SignupStep({
  email,
  customError,
  onSubmit,
  onGoBack,
}: SignupStepProps) {
  const { t } = useTranslation('login');

  return (
    <SignupStepContainer>
      <StepHeader onButtonClick={onGoBack} type={StepHeaderType.GO_BACK} />
      <SignupStepHeader>
        <SignupStepTitle data-testid="signup-title">
          {t('signup-step-title')}
        </SignupStepTitle>
      </SignupStepHeader>
      <SignupStepFormContainer>
        <FormSignup
          email={email}
          customError={customError}
          onClickToSubmit={onSubmit}
        />
      </SignupStepFormContainer>
    </SignupStepContainer>
  );
}

export default SignupStep;
