import { Icon, Theme } from '@pelando/components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const GoBackButtonIcon = styled(Icon)`
  color: rgb(${Theme.colors.Alpha});
  font-size: 24px;
  margin-right: auto;

  &:hover {
    cursor: pointer;
  }
`;
