import { FontFamily } from '@pelando/components';
import styled from 'styled-components';

export const FormTitleContainer = styled.h1`
  font-size: 28px;
  line-height: 1.29;
  font-family: ${FontFamily.DM_SANS};
  font-weight: 700;
  margin-bottom: 12px;
`;
