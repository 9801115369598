import { Button } from '@pelando/components';
import styled, { css } from 'styled-components';
import { AuthProvider, SocialProvidersType } from '../../types';

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */

type SocialButtonProp = {
  provider: SocialProvidersType;
};

const googleStyles = css`
  &::after {
    content: '';
    height: 24px;
    width: 24px;
    display: block;
    background-image: url('/assets/google.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
`;

const facebookStyles = css`
  i {
    color: #4267b2;
  }
`;

const appleStyles = css`
  i {
    color: rgb(${({ theme }) => theme.colors.Alpha});
  }
`;

const providerStyles = {
  [AuthProvider.APPLE]: appleStyles,
  [AuthProvider.FACEBOOK]: facebookStyles,
  [AuthProvider.GOOGLE]: googleStyles,
};

export const SocialButtonContainer = styled(Button)<SocialButtonProp>`
  width: 48px;
  height: 48px;
  ${({ provider }) => providerStyles[provider]}
`;
