import React, { ReactNode } from 'react';
import { AgreementType } from '@/domain/entities/Agreement';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { ChevronRight, Envelope } from '@pelando/fontawesome/icons';
import { Icon } from '@pelando/components';
import { URL_APP_LOGIN } from '@/presentation/services/urls';
import { useRouter } from 'next/router';
import { useLocale } from '@/presentation/hooks/useLocale';
import {
  SocialLoginContainer,
  SocialLoginModalSubtitle,
  LoginButtonsGroup,
  TermsOfUse,
  SocialLoginIcon,
  SocialLoginButton,
  SocialLoginButtonContainer,
  SocialLoginModalTitleDesktop,
  SocialLoginModalTitleMobile,
  LoginDividerContent,
  LoginDivisorLine,
  LoginLabelDividerText,
  AppButton,
  EmailLoginButton,
} from './style';
import StepHeader from '../components/StepHeader';
import ExternalAgreementLink from '../components/ExternalAgreementLink';
import { AuthProvider, LoginComponentSourceName } from '../types';

import GoogleLoginBtn from '../../GoogleLoginButton';

export type IncentiveSocialLoginStepProps = {
  className?: string;
  modalTitle?: ReactNode;
  modalSubtitle?: ReactNode;
  onGoToEmailStep: () => void;
  onCloseModal: () => void;
  componentSourceName: LoginComponentSourceName;
  googleClientId?: string;
  onPick: (provider: AuthProvider) => void;
  onFinished?: () => void;
};

function IncentiveSocialLoginStep({
  className,
  modalTitle,
  modalSubtitle,
  onGoToEmailStep,
  onCloseModal,
  googleClientId,
  onPick,
  onFinished,
}: IncentiveSocialLoginStepProps) {
  const { t } = useTranslation('login');
  const { asPath } = useRouter();
  const locale = useLocale();
  const mapAgreementTypeToText = (agreementType: AgreementType) =>
    ((
      {
        [AgreementType.PRIVACY_POLICY]: t('login-privacy-policy'),
        [AgreementType.TERMS_OF_SERVICE]: t('login-terms-of-service'),
      } as Record<AgreementType, string>
    )[agreementType]);

  const isPTBR = locale === 'pt-BR';

  return (
    <>
      <SocialLoginContainer className={className}>
        <StepHeader onButtonClick={onCloseModal} />
        <SocialLoginModalTitleDesktop>
          {modalTitle || <>{t('login-title-modal')}</>}
        </SocialLoginModalTitleDesktop>
        <SocialLoginModalTitleMobile>
          {/* TODO: Alterar texto quando houver a versão do aplicativo para o PromoNinja */}
          {modalTitle || <>{t('login-title-incentive-app')}</>}
        </SocialLoginModalTitleMobile>
        <SocialLoginModalSubtitle>
          {modalSubtitle || t('auth-modal-subtitle')}
        </SocialLoginModalSubtitle>
        {isPTBR && (
          <>
            <AppButton url={URL_APP_LOGIN} target="_blank">
              {t('login-app-button')}
              <Icon icon={ChevronRight} />
            </AppButton>
            <LoginDividerContent>
              <LoginDivisorLine />
              <LoginLabelDividerText>
                {t('login-label-divider')}
              </LoginLabelDividerText>
              <LoginDivisorLine />
            </LoginDividerContent>
          </>
        )}
        <SocialLoginButtonContainer>
          <GoogleLoginBtn
            googleClientId={googleClientId}
            path={asPath}
            onFinished={onFinished}
          />
          <LoginButtonsGroup>
            <SocialLoginButton
              provider={AuthProvider.APPLE}
              responsive
              onClick={() => onPick(AuthProvider.APPLE)}
            />
            <SocialLoginButton
              provider={AuthProvider.FACEBOOK}
              responsive
              onClick={() => onPick(AuthProvider.FACEBOOK)}
            />
            <EmailLoginButton
              light
              onClick={() => {
                onGoToEmailStep();
              }}
            >
              <SocialLoginIcon icon={Envelope} />
            </EmailLoginButton>
          </LoginButtonsGroup>
        </SocialLoginButtonContainer>
        <TermsOfUse>
          {t('login-terms-of-use-text')}
          <ExternalAgreementLink
            agreementType={AgreementType.TERMS_OF_SERVICE}
            getAgreementLinkText={mapAgreementTypeToText}
          />{' '}
          {t('login-terms-of-use-joining')}
          <ExternalAgreementLink
            agreementType={AgreementType.PRIVACY_POLICY}
            getAgreementLinkText={mapAgreementTypeToText}
          />
        </TermsOfUse>
      </SocialLoginContainer>
    </>
  );
}
export default IncentiveSocialLoginStep;
