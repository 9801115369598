import {
  BodyLight,
  Button,
  Colors,
  FontFamily,
  H3Heavy,
  Icon,
  MediaQuery,
  SmallLight,
  Theme,
} from '@pelando/components';
import styled, { css } from 'styled-components';
import SocialMediaButton from '../components/SocialMediaButton';

export const SocialLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const SocialLoginModalTitleDesktop = styled.h1`
  ${H3Heavy}
  text-align: center;
  display: none;
  margin-top: 16px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: unset;
  }
`;

export const SocialLoginModalTitleMobile = styled.h1`
  ${H3Heavy}
  text-align: center;
  display: unset;
  margin-top: 8px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const SocialLoginModalSubtitle = styled.div`
  display: none;
  ${BodyLight}
  text-align: center;
  color: rgb(${Colors.Gray});
  margin-top: 8px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: unset;
  }
`;

export const AppButton = styled(Button)`
  width: 100%;
  margin: 16px 0;
  gap: 8px;

  i {
    font-size: 24px;
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const LoginDividerContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  color: rgb(${Colors.Gray});
  font-family: ${FontFamily.DM_SANS};
  p {
    margin: 0 16px;
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const LoginDivisorLine = styled.span`
  height: 1px;
  width: 100%;
  background-color: rgb(${Theme.colors.Golf});
`;

export const LoginLabelDividerText = styled.p`
  white-space: nowrap;
`;

export const SocialLoginButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

export const LoginButtonsGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const buttonsStyles = css`
  height: 40px;
  border-radius: 12px;

  width: 104px;
  padding: 0;
  i {
    margin-right: 0;
  }
`;

export const SocialLoginButton = styled(SocialMediaButton)`
  & {
    ${buttonsStyles}
  }
`;

export const EmailLoginButton = styled(Button)`
  ${buttonsStyles}
`;

export const SocialLoginIcon = styled(Icon)`
  color: rgb(${Theme.colors.Alpha});
  font-size: 24px;
`;

export const TermsOfUse = styled.div`
  ${SmallLight}
  color: rgb(${Colors.Gray});
  margin: 16px 68px 0;
  text-align: center;

  a {
    color: rgb(${Colors.Gray});
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin: 16px 0 0;
  }
`;
