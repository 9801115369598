import React from 'react';

import TitleContainer from './style';

export enum TitleAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export type TitleProps = {
  children: React.ReactNode;
  align?: TitleAlign;
  className?: string;
  secondary?: boolean;
};

function Title({ children, align, className, secondary }: TitleProps) {
  return <TitleContainer
    align={align}
    className={className}
    {...(secondary && { as: 'h2' })}
  >
    {children}
  </TitleContainer>
}

export default Title;
