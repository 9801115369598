import {
  setUserIsProbablyLoggedCookie,
  setAuthTokenToLocalStorage,
} from '../../../auth/tokenManagement';
import { ClientInfoMetadata, clearUnloggedId } from '../../core/tracker';
import { login } from '../endpoints';
import { LoginVariables } from '../types/Login';

export const loginHelper = ({
  variables,
  metadata,
}: {
  variables: LoginVariables;
  metadata?: ClientInfoMetadata;
}) =>
  login.requestAsPromise(variables, metadata).then(({ data, error }) => {
    if (error) return;
    setAuthTokenToLocalStorage(data as string);
    setUserIsProbablyLoggedCookie(!!data);
    clearUnloggedId();
  });
