import { Icon, TextInput, TextInputProps } from '@pelando/components';
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TriangleExclamation } from '@pelando/fontawesome/icons';
import { IconDefinition } from '@pelando/fontawesome';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import validate, { RulesValidate } from '../../../../services/validator';
import FormTitle from '../FormTitle';
import { TitleAlign } from '../Title';

import {
  FormInputButton,
  FormInputContainer,
  FormInputErrorMessageContainer,
  FormInputFieldContainer,
  FormInputFieldPrefix,
  FormInputFieldSuffix,
  FormInputOptional,
  FormSubtitle,
  TextInputContainer,
} from './style';

/**
 * TODO refactor dependants using StepForm component and delete this component
 */
export type FormInputProps = TextInputProps & {
  className?: string;
  title?: string;
  subtitle?: string;
  required?: boolean;
  prefix?: string;
  suffix?: string;
  btnText?: string;
  btnIcon?: IconDefinition;
  validations?: {
    rules: RulesValidate;
    errorMessage: string;
  };
  customError?: string;
  forceInvalidation?: boolean;
  upload?: boolean;
  emoji?: boolean;
  loading?: boolean;
  defaultValue?: string;

  formatter?: (value: string) => string;
  onSubmit: (value: string) => void;
  onChangeHandler?: (value: string) => void;
  onSubmitFail?: () => void;
  uploadHandler?: (file: File) => Promise<string | null>;
  onOpenImageDialog?: (open: () => void) => void;
};

function FormInput({
  className,
  title,
  subtitle,
  placeholder,
  maxLength,
  prefix,
  suffix,
  required = false,
  formatter = (value) => value,
  onSubmit,
  onChangeHandler,
  onSubmitFail,
  defaultValue,
  btnText = 'Próximo',
  btnIcon,
  validations,
  customError,
  forceInvalidation,
  autoFocus = true,
  loading,
  type,
  ...rest
}: FormInputProps) {
  const { t } = useTranslation('login');
  const [value, setValue] = useState(formatter(''));
  const [isInvalid, setIsInvalid] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [inputRef, autoFocus]);

  const handleValidation = useCallback(() => {
    if (validations) {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      if (!validate(inputRef.current!.value, validations.rules)) {
        return false;
      }
    }

    return true;
  }, [validations]);

  const handleChange = (inputValue: string) => {
    setValue(formatter(inputValue));

    if (onChangeHandler) {
      onChangeHandler(inputValue);
    }

    if (isInvalid && handleValidation()) {
      setIsInvalid(false);
    }
  };

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (handleValidation()) {
        onSubmit(value);
      } else {
        if (onSubmitFail) {
          onSubmitFail();
        }
        setIsInvalid(true);
        inputRef.current!.focus();
      }
    },
    [handleValidation, onSubmit, value, onSubmitFail]
  );

  const hasError = !!(customError || (isInvalid && value !== ''));

  return (
    <FormInputContainer onSubmit={handleSubmit} className={className}>
      {!required && <FormInputOptional>(opcional)</FormInputOptional>}
      {!!title && <FormTitle align={TitleAlign.CENTER}>{title}</FormTitle>}
      {!!subtitle && <FormSubtitle>{subtitle}</FormSubtitle>}
      <FormInputFieldContainer>
        <TextInputContainer>
          {prefix && <FormInputFieldPrefix>{prefix}</FormInputFieldPrefix>}
          <TextInput
            innerRef={inputRef}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event.target.value)
            }
            showRemainingChars={!!maxLength}
            maxLength={maxLength}
            invalid={forceInvalidation || isInvalid}
            autoFocus={autoFocus}
            type={type}
            {...rest}
          />
          {suffix && <FormInputFieldSuffix>{suffix}</FormInputFieldSuffix>}
        </TextInputContainer>
      </FormInputFieldContainer>
      <FormInputButton
        loading={loading}
        icon={btnIcon}
        disabled={
          forceInvalidation || (required && (isInvalid || value === ''))
        }
        type="submit"
      >
        {btnText}
      </FormInputButton>
      {hasError && (
        <FormInputErrorMessageContainer>
          <Icon
            icon={TriangleExclamation}
            aria-label={t('form-input-error-icon-aria-label')}
          />
          {customError || validations?.errorMessage}
        </FormInputErrorMessageContainer>
      )}
    </FormInputContainer>
  );
}

export default FormInput;
