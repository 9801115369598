import React from 'react';
import { TitleProps } from '../Title';

import { FormTitleContainer } from './style';

type FormTitleProps = TitleProps;

function FormTitle({ children, ...rest }: FormTitleProps) {
  return <FormTitleContainer {...rest}>{children}</FormTitleContainer>;
}

export default FormTitle;
